<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="flex flex-wrap justify-between items-center w-90 center pv4-l pv3 mini-spacing">
                <div class="w-100">
                    <div>
                        <router-link :to="{ name: 'Reports' }" class="font14 b" style="color: #132c8c;"
                            >&lt; Report</router-link
                        >
                        <div class="pv4 f3 b">Sales Reports </div>
                    </div>
                    <div class="flex mbWrap justify-between w-100 gap8 pb3" style="align-items: flex-end">
                        <div class="w-30 mbPadd2">
                            <label class="block f500 font14 colorLabel" for="report-to">From</label>
                            <input type="date" v-model="state.startDate" class="trialDate w-100" id="report-to" />
                        </div>
                        <div class="w-30 mbPadd2">
                            <label class="block f500 font14 colorLabel" for="report-from">To</label>
                            <input type="date" v-model="state.endDate" class="trialDate w-100" id="report-from" @change="handleSalesRecordsByDate" />
                        </div>
                        <select name="Filer" v-model="state.filter" @change="handleSalesRecords" class="w-30 trialDate mbPadd">
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="Annually">Annually</option>
                        </select>
                        <div class="filter">
                            <div class="tc flex items-center" @click="handleSalesRecords">
                                <div><img src="@/assets/images/undo.svg" alt="filter icon" /></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="reportFilter w-100">

                        <div class="filter mr3 ml3" @click="handleSalesRecords">
                            <div class="tc flex items-center">
                                <div><img src="@/assets/images/filter_list.svg" alt="filter icon" /></div>
                                <div class="pl2 tl">Filter</div>
                            </div>
                        </div>
                        <button class="flex items-center pv2">
                            <span><img :src="require('@/assets/images/search3.svg')" /></span>
                            <span class="pl2 tl">Search</span>
                        </button>
                    </div> -->

                    <div class="overflow-auto no-wrap w-100 center mt20">
                        <table class="w-100 tl mt3" cellspacing="0">
                            <thead>
                                <tr class="flex gap16 justify-between w-100 mt3 p-10 headerColor" style="gap: 16px">
                                    <th class="b font14 w-25">Day</th>
                                    <th class="b font14 w-25 tc">Qty Sold</th>
                                    <th class="b font14 w-25 tc mr4">No. of Customers</th>
                                    <th class="b font14 w-25 tr">Revenue</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="result in state.result"
                                    :key="result.filter"
                                    class="flex justify-between w-100 p-10"
                                    style="gap: 16px"
                                >
                                    <td class="font14 w-25">{{ result.filter }}</td>
                                    <td class="font14 w-25 tc">{{ result.qtySold }}</td>
                                    <td class="font14 w-25 tc mr4">{{ result.noOfCustomer }}</td>
                                    <td class="font14 w-25 tr">
                                        {{ formatAmount(result.revenue, $store.state.Settings?.currency) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="reportFilter mt4">
                        <button @click="downloadFile" class="flex items-center">
                            <span>Export</span><span><img :src="require('@/assets/images/white-caret.svg')" /></span>
                        </button>
                    </div> -->
                    <div class="reportFilter mt4">
                        <button
                            style="padding: 10px 20px"
                            class="dropdown-togglex"
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <div class="flex items-center">
                                <span style="margin-right: 10px">Export</span>
                                <span><img :src="require('@/assets/images/white-caret.svg')" /></span>
                            </div>
                        </button>
                        <ul class="dropdown-menu mbDrop" aria-labelledby="dropdownMenuLink" style="cursor: pointer">
                            <li @click="downloadFile">Download as <span class="b">CSV</span> File</li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { useStore } from 'vuex'

import { computed, onMounted, reactive, watch } from 'vue'
import { formatAmount, formatDateInLocal, formatDateFilter, downloadCSV } from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper'
import OrgData from '@/components/OrgData'

export default {
    name: 'SalesReportNew',
    components: { AppWrapper, OrgData },

    setup() {
        const store = useStore()
        const orgData = computed(() => store.state.Auth.userData)
        const report = computed(() => store.state.Reporting.report)

        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const day = new Date().getDate()

        const tableHead = {
            monthly: 'Month',
            daily: 'Day',
            weekly: 'Week',
            annualyy: 'Year',
        }

        const downloadFile = () => {
            downloadCSV(state.result)
        }

        const state = reactive({
            startDate: '',
            endDate: '',
            filter: 'Daily',
            result: [],
        })

        const handleSalesRecords = () => {
            if (state.filter.toLowerCase() === 'daily') {
                state.startDate = formatDateFilter(new Date(year, month, 1))
                state.endDate = formatDateFilter(new Date(year, month, day))

                //Daily: 1st of current Month - Current date
                // console.log('daily is triggered', state.startDate, state.endDate)
            } else if (state.filter.toLowerCase() === 'weekly') {
                // 1st week of the Year to current week. Week starts from Monday//
                state.startDate = formatDateFilter(new Date(year, 0, 1))
                state.endDate = formatDateFilter(new Date(year, month, day))
                // console.log('weekly is triggered', state.startDate, state.endDate)
            } else if (state.filter.toLowerCase() === 'monthly') {
                // Monthly: Jan of the current year - Current month
                state.startDate = formatDateFilter(new Date(year, 0, 1))
                state.endDate = formatDateFilter(new Date(year, month, day))
                // console.log('monthly is triggered', state.startDate, state.endDate)
            } else if (state.filter.toLowerCase() === 'annually') {
                // 2021 to current Year
                state.startDate = formatDateFilter(new Date(2021, 0, 1))
                state.endDate = formatDateFilter(new Date(year, month, day))
                // console.log('yearly is triggered', state.startDate, state.endDate)
            }
            if (state.startDate > state.endDate) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Start date cannot be greater than end date',
                    status: false,
                })
            }

            if (!state.filter) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Filter is not selected',
                    status: false,
                })
            }

            store.dispatch('Reporting/getSalesReports', {
                startDate: state.startDate,
                endDate: state.endDate,
                filter: state.filter,
            })

            // console.log(state.startDate, state.endDate, state.filter, 'state.startDate, state.endDate, state.filter')
        }



      const handleSalesRecordsByDate = () => {
        store.dispatch('Reporting/getSalesReports', {
                startDate: state.startDate,
                endDate: state.endDate,
                filter: state.filter,
            })
      }

        const allDay = [
            {
                day: 'Monday',
                value: 1,
            },
            {
                day: 'Tuesday',
                value: 2,
            },
            {
                day: 'Wednesday',
                value: 3,
            },
            {
                day: 'Thursday',
                value: 4,
            },
            {
                day: 'Friday',
                value: 5,
            },
            {
                day: 'Saturday',
                value: 6,
            },
            {
                day: 'Sunday',
                value: 7,
            },
        ]
        const allWeek = [
            {
                week: 'Week 1',
                value: 1,
            },
            {
                week: 'Week 2',
                value: 2,
            },
            {
                week: 'Week 3',
                value: 3,
            },
            {
                week: 'Week 4',
                value: 4,
            },
            {
                week: 'Week 5',
                value: 5,
            },
            {
                week: 'Week 6',
                value: 6,
            },
            {
                week: 'Week 7',
                value: 7,
            },
            {
                week: 'Week 8',
                value: 8,
            },
            {
                week: 'Week 9',
                value: 9,
            },
            {
                week: 'Week 10',
                value: 10,
            },
            {
                week: 'Week 11',
                value: 11,
            },
            {
                week: 'Week 12',
                value: 12,
            },
            {
                week: 'Week 13',
                value: 13,
            },
            {
                week: 'Week 14',
                value: 14,
            },
            {
                week: 'Week 15',
                value: 15,
            },
            {
                week: 'Week 16',
                value: 16,
            },
            {
                week: 'Week 17',
                value: 17,
            },
            {
                week: 'Week 18',
                value: 18,
            },
            {
                week: 'Week 19',
                value: 19,
            },
            {
                week: 'Week 20',
                value: 20,
            },
            {
                week: 'Week 21',
                value: 21,
            },
            {
                week: 'Week 22',
                value: 22,
            },
            {
                week: 'Week 23',
                value: 23,
            },
            {
                week: 'Week 24',
                value: 24,
            },
            {
                week: 'Week 25',
                value: 25,
            },
            {
                week: 'Week 26',
                value: 26,
            },
            {
                week: 'Week 27',
                value: 27,
            },
            {
                week: 'Week 28',
                value: 28,
            },
            {
                week: 'Week 29',
                value: 29,
            },
            {
                week: 'Week 30',
                value: 30,
            },
            {
                week: 'Week 31',
                value: 31,
            },
            {
                week: 'Week 32',
                value: 32,
            },
            {
                week: 'Week 33',
                value: 33,
            },
            {
                week: 'Week 34',
                value: 34,
            },
            {
                week: 'Week 35',
                value: 35,
            },
            {
                week: 'Week 36',
                value: 36,
            },
            {
                week: 'Week 37',
                value: 37,
            },
            {
                week: 'Week 38',
                value: 38,
            },
            {
                week: 'Week 39',
                value: 39,
            },
            {
                week: 'Week 40',
                value: 40,
            },
            {
                week: 'Week 41',
                value: 41,
            },
            {
                week: 'Week 42',
                value: 42,
            },
            {
                week: 'Week 43',
                value: 43,
            },
            {
                week: 'Week 44',
                value: 44,
            },
            {
                week: 'Week 45',
                value: 45,
            },
            {
                week: 'Week 46',
                value: 46,
            },
            {
                week: 'Week 47',
                value: 47,
            },
            {
                week: 'Week 48',
                value: 48,
            },
            {
                week: 'Week 49',
                value: 49,
            },
            {
                week: 'Week 50',
                value: 50,
            },
            {
                week: 'Week 51',
                value: 51,
            },
            {
                week: 'Week 52',
                value: 52,
            },
        ]
        const allMonth = [
            {
                month: 'January',
                value: 1,
            },
            {
                month: 'February',
                value: 2,
            },
            {
                month: 'March',
                value: 3,
            },
            {
                month: 'April',
                value: 4,
            },
            {
                month: 'May',
                value: 5,
            },
            {
                month: 'June',
                value: 6,
            },
            {
                month: 'July',
                value: 7,
            },
            {
                month: 'August',
                value: 8,
            },
            {
                month: 'September',
                value: 9,
            },
            {
                month: 'October',
                value: 10,
            },
            {
                month: 'November',
                value: 11,
            },
            {
                month: 'December',
                value: 12,
            },
        ]
        const allYear = [
            {
                year: '2021',
                value: 2021,
            },
            {
                year: '2022',
                value: 2022,
            },
            {
                year: '2023',
                value: 2023,
            },
            {
                year: '2024',
                value: 2024,
            },
            {
                year: '2025',
                value: 2025,
            },
            {
                year: '2026',
                value: 2026,
            },
            {
                year: '2027',
                value: 2027,
            },
            {
                year: '2028',
                value: 2028,
            },
            {
                year: '2029',
                value: 2029,
            },
            {
                year: '2030',
                value: 2030,
            },
        ]

        const getDailyResult = (report) => {
            //    const dayResult = allDay.filter((day => day.value >= report[0].day)).map(( day => {
            if (report && report.length) {
                const dayResult = report.map((day) => {
                    if (day) {
                        return {
                            filter: day.day,
                            qtySold: day.quantitySold,
                            noOfCustomer: day.numberOfCustomers,
                            revenue: day.revenue,
                        }
                    } else {
                        return {
                            filter: day,
                            qtySold: 0,
                            noOfCustomer: 0,
                            revenue: 0,
                        }
                    }
                })
                console.log(dayResult, 'day result')
                state.result = dayResult
                // const foundDay = report.find(r => r.day !== day.value)
            }
            return {
                filter: '',
                qtySold: 0,
                noOfCustomer: 0,
                revenue: 0,
            }
        }

        const getWeeklyResult = (report) => {
            //    const dayResult = allDay.filter((day => day.value >= report[0].day)).map(( day => {
            if (report && report.length) {
                const weekResult = report.map((week) => {
                    if (week) {
                        return {
                            filter: week.week,
                            qtySold: week.quantitySold,
                            noOfCustomer: week.numberOfCustomers,
                            revenue: week.revenue,
                        }
                    } else {
                        return {
                            filter: '',
                            qtySold: 0,
                            noOfCustomer: 0,
                            revenue: 0,
                        }
                    }
                })
                console.log(weekResult, 'day result')
                state.result = weekResult
            }
            return {
                filter: '',
                qtySold: 0,
                noOfCustomer: 0,
                revenue: 0,
            }
        }

        const getMonthlyResult = (report) => {
            //    const dayResult = allDay.filter((day => day.value >= report[0].day)).map(( day => {
            if (report && report.length) {
                const monthlyResult = report.map((month) => {
                    if (month) {
                        return {
                            filter: month.month,
                            qtySold: month.quantitySold,
                            noOfCustomer: month.numberOfCustomers,
                            revenue: month.revenue,
                        }
                    } else {
                        return {
                            filter: '',
                            qtySold: 0,
                            noOfCustomer: 0,
                            revenue: 0,
                        }
                    }
                })
                // console.log(monthlyResult, 'day result')
                state.result = monthlyResult
            }
            return {
                filter: '',
                qtySold: 0,
                noOfCustomer: 0,
                revenue: 0,
            }
        }

        const getWeeklyResult_ = (report) => {
            const weekResult = allWeek
                .filter((weeks) => weeks.value <= report[report.length - 1].week)
                .map((week) => {
                    if (report && report.length) {
                        const foundWeek = report.find((r) => r.week === week.value)
                        if (foundWeek) {
                            return {
                                filter: week.week,
                                qtySold: foundWeek.quantitySold,
                                noOfCustomer: foundWeek.numberOfCustomers,
                                revenue: foundWeek.revenue,
                            }
                        } else {
                            return {
                                filter: week.week,
                                qtySold: 0,
                                noOfCustomer: 0,
                                revenue: 0,
                            }
                        }
                    }
                    return {
                        filter: week.week,
                        qtySold: 0,
                        noOfCustomer: 0,
                        revenue: 0,
                    }
                })
            console.log(weekResult, 'week result')
            state.result = weekResult
        }

        const getMonthlyResult_ = (report) => {
            const monthResult = allMonth
                .filter((months) => months.value >= report[0].month)
                .map((month) => {
                    if (report && report.length) {
                        const foundMonth = report.find((r) => r.month === month.value)
                        if (foundMonth) {
                            return {
                                filter: month.month,
                                qtySold: foundMonth.quantitySold,
                                noOfCustomer: foundMonth.numberOfCustomers,
                                revenue: foundMonth.revenue,
                            }
                        } else {
                            return {
                                filter: month.month,
                                qtySold: 0,
                                noOfCustomer: 0,
                                revenue: 0,
                            }
                        }
                    }
                    return {
                        filter: month.month,
                        qtySold: 0,
                        noOfCustomer: 0,
                        revenue: 0,
                    }
                })
            console.log(monthResult, 'monthly result')
            state.result = monthResult
        }

        const getYearlyResult = (report) => {
            const yearResult = allYear
                .filter((year) => year.value <= report[0].year)
                .map((year) => {
                    if (report && report.length) {
                        console.log(report)
                        const foundYear = report.find((r) => r.year === year.value)
                        if (foundYear) {
                            return {
                                filter: year.year,
                                qtySold: foundYear.quantitySold,
                                noOfCustomer: foundYear.numberOfCustomers,
                                revenue: foundYear.revenue,
                            }
                        } else {
                            return {
                                filter: year.year,
                                qtySold: 0,
                                noOfCustomer: 0,
                                revenue: 0,
                            }
                        }
                    }
                    return {
                        filter: year.year,
                        qtySold: 0,
                        noOfCustomer: 0,
                        revenue: 0,
                    }
                })
            console.log(yearResult, 'yearly result')
            state.result = yearResult
        }

        watch(
            () => report.value,
            () => {
                if (report.value) {
                    console.log(report.value, state.filter, 'report.value')
                    switch (state.filter.toLowerCase()) {
                        case 'daily':
                            getDailyResult(report.value)
                            break
                        case 'weekly':
                            getWeeklyResult(report.value)
                            break
                        case 'monthly':
                            getMonthlyResult(report.value)
                            break
                        case 'annual':
                            getYearlyResult(report.value)
                            break
                        default:
                            break
                    }
                }
            }
        )

        onMounted(() => {
            const startDate = new Date(year, month, 1) // new Date(year, 0, 1)
            const endDate = new Date(year, month, day)
            const filter = 'Daily' // 'monthly'

            // console.log(startDate, endDate, filter, 'startdate, endDate, filter')

            const query = {
                startDate: startDate,
                endDate: endDate,
                filter: filter,
            }

            state.startDate = formatDateFilter(startDate)
            state.endDate = formatDateFilter(endDate)

            store.dispatch('Reporting/getSalesReports', query)
        })

        return {
          downloadFile,
          orgData,
          report,
          state,
          handleSalesRecords,
          handleSalesRecordsByDate,
          tableHead,
          formatDateInLocal,
          formatDateFilter,
          formatAmount,
        }

        const filterReport = (query) => {
            store.dispatch('Reporting/getSalesReports', query)
        }

        return {
            orgData,
            filterReport,
        }
    },
}
</script>
<style>
.w-25 {
    width: 25%;
}
</style>
